import { createContext, useCallback, useEffect, useState } from 'react';
import './App.css';
import Calendar from './components/calender/Calender';
// import SendMessageButton from './components/message-button';
import DatePickerModalView from './components/modal-view/DatePickerModalView';
import Modal from './components/Modals/modal with create Portal/Modal';

function App() {
  const [modal, setModal] = useState(false);
  const [scheduleData, updateScheduleData] = useState([]);
  const clickOnReminder = useCallback(() => {
    setModal(true);
  }, [setModal]);

  const getScheduleData = async () => {
    const res = await fetch('https://gms.thinkfoundation.com.bd/api/schedule/all', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (res.ok === true) {
      const data = await res.json();
      updateScheduleData(data.data);
    } else {
      // alert('Data feting failed');
    }
  };

  useEffect(() => {
    getScheduleData();
  }, []);

  return (
    <div className='App'>
      {modal && (
        <Modal>
          <DatePickerModalView context={getScheduleData} onClose={() => setModal()} />
        </Modal>
      )}
      {/* <SendMessageButton /> */}
      <div className='h-screen-nav-fix w-screen font-montserrat overflow-hidden bg-gray-50 text-gray-900'>
        <Calendar onClick={clickOnReminder} month='January' year={2023} monthOf={31} selectedArray={scheduleData} />
      </div>
    </div>
  );
}

export default App;
