import React, { useEffect, useState } from 'react';

export default function SwitchComponent() {
  const [value, setValue] = useState(null);

  const postData = async () => {
    const res = await fetch('https://gms.thinkfoundation.com.bd/api/schedule/button', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ publish: value }),
    });
    if (res.ok === true) {
      // alert('alarm set successfully');
    } else {
      alert('Something is wrong');
    }
  };
  useEffect(() => {
    if (value !== null) {
      postData();
    }
  }, [value]);
  return (
    <div onClick={() => setValue(!value)} className={` p-2 relative rounded-lg  ${value ? 'bg-indigo-600 justify-end' : 'bg-slate-500'}`}>
      <div
        className={`w-2/5 absolute left-0.5 top-1/2 -translate-y-1/2 aspect-square  rounded-full bg-indigo-300 ${value && 'left-[calc(50%-2px)]'}`}
      ></div>
    </div>
  );
}
