import dayjs from 'dayjs';
import React from 'react';
import DefaultInput from '../default-input/DefaultInput';
import SwitchComponent from '../switch/Switch';
import logo from '../../assets/think.png';
import Switch1 from '../switch1/Switch1';
import Switch2 from '../switch2/Switch2';

export function getMonthDay(month = '') {}

export function parseDateTime(seconds) {
  let tem = dayjs(seconds * 1000);

  let parseDate = {
    date: dayjs(tem).format('MMM D, YYYY'),
    time: dayjs(tem).format('hh:mm a'),
  };
  return parseDate;
}

function Calendar({ onClick = () => {}, monthInInt = 1, year = 2023, monthOf = 31, label = 'January 2023', selectedArray = [] }) {
  const days = [...Array(35)]?.map((ar, i) => {
    const d = new Date();
    d.setDate(i + 1);
    d.setMonth(monthInInt - 1);
    d.setFullYear(year);
    const dInSec = d.getTime() / 1000;
    let startTime = null;
    let endTime = null;
    selectedArray?.forEach((eh, i) => {
      let comp1 = parseDateTime(dInSec).date;
      let comp2 = parseDateTime(eh.start_time).date;
      if (comp1 === comp2) {
        startTime = parseDateTime(eh.start_time).time;
        endTime = parseDateTime(eh.end_time).time;
      }
    });

    return { full_date: d, start_time: startTime, end_time: endTime };
  });

  console.log(days);
  return (
    <div>
      <div className='w-full h-full flex flex-col'>
        <div className='flex px-8 py-4 flex-row gap-4 flex-nowrap shadow-lg items-center justify-between'>
          <div className='flex items-center'>
            <img className='w-10 mr-2' src={logo} alt='' />
            <button
              onClick={onClick}
              type='button'
              className='uppercase flex flex-row flex-nowrap items-center gap-2 p-3 lg:px-4 lg:py-2 shadow rounded-full lg:rounded text-lg font-medium bg-white hover:bg-gray-200 text-indigo-700 hover:text-indigo-900 transition-colors duration-150'
            >
              <i>
                <svg className='w-6 h-6' xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
                  <path d='M12 5.5V19.5' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'></path>
                  <path d='M5 12.5H19' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'></path>
                </svg>
              </i>
              <span className='hidden lg:inline'>New Reminder</span>
            </button>
          </div>
          <div className=''>
            <h1 className='flex-grow text-center text-2xl font-medium'>{label}</h1>
          </div>
          <div className='flex items-center'>
            <div className='w-20'>
              <Switch2 />
            </div>
            <div className='w-20'>
              <Switch1 />
            </div>
            <DefaultInput />
            <div className='w-8 ml-2'>
              <SwitchComponent />
            </div>
          </div>
        </div>
        <div className='w-full flex-grow overflow-hidden flex flex-col'>
          <div className='w-full flex-shrink p-1 bg-gradient-to-r from-indigo-700 to-purple-800 text-white shadow-md grid grid-cols-7 gap-1'>
            <h3 className='px-2 py-2 text-xs sm:text-sm md:text-base lg:text-lg font-medium text-center flex items-center justify-center'>
              <span className='hidden lg:inline'>Sunday</span>
              <span className='hidden md:inline lg:hidden'>Sun</span>
              <span className='inline md:hidden'>S</span>
            </h3>
            <h3 className='px-2 py-2 text-xs sm:text-sm md:text-base lg:text-lg font-medium text-center flex items-center justify-center'>
              <span className='hidden lg:inline'>Monday</span>
              <span className='hidden md:inline lg:hidden'>Mon</span>
              <span className='inline md:hidden'>M</span>
            </h3>
            <h3 className='px-2 py-2 text-xs sm:text-sm md:text-base lg:text-lg font-medium text-center flex items-center justify-center'>
              <span className='hidden lg:inline'>Tuesday</span>
              <span className='hidden md:inline lg:hidden'>Tue</span>
              <span className='inline md:hidden'>T</span>
            </h3>
            <h3 className='px-2 py-2 text-xs sm:text-sm md:text-base lg:text-lg font-medium text-center flex items-center justify-center'>
              <span className='hidden lg:inline'>Wednesday</span>
              <span className='hidden md:inline lg:hidden'>Wed</span>
              <span className='inline md:hidden'>W</span>
            </h3>
            <h3 className='px-2 py-2 text-xs sm:text-sm md:text-base lg:text-lg font-medium text-center flex items-center justify-center'>
              <span className='hidden lg:inline'>Thursday</span>
              <span className='hidden md:inline lg:hidden'>Thu</span>
              <span className='inline md:hidden'>T</span>
            </h3>
            <h3 className='px-2 py-2 text-xs sm:text-sm md:text-base lg:text-lg font-medium text-center flex items-center justify-center'>
              <span className='hidden lg:inline'>Friday</span>
              <span className='hidden md:inline lg:hidden'>Fri</span>
              <span className='inline md:hidden'>F</span>
            </h3>
            <h3 className='px-2 py-2 text-xs sm:text-sm md:text-base lg:text-lg font-medium text-center flex items-center justify-center'>
              <span className='hidden lg:inline'>Saturday</span>
              <span className='hidden md:inline lg:hidden'>Sat</span>
              <span className='inline md:hidden'>S</span>
            </h3>
          </div>
          <ol className='w-full flex-grow overflow-y-auto px-1 pt-0 pb-1 bg-gray-200 grid grid-cols-7 auto-rows-fr gap-1'>
            {days?.map((item, i) => (
              <li
                key={i}
                className={`h-16 px-3 py-2 bg-white text-lg overflow-hidden font-normal ${item?.start_time && item.end_time && 'text-indigo-600'}`}
              >
                {item.full_date.getDate()} <br />
                {item.start_time && item.end_time && (
                  <span className='text-sm text-black'>
                    {item.start_time} to {item.end_time}
                  </span>
                )}
              </li>
            ))}
          </ol>
        </div>
      </div>
    </div>
  );
}

export default React.memo(Calendar);
