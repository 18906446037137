import React, { useState } from 'react';
import { postInputValue } from '../../api';

export default function DefaultInput() {
  const [inputValue, setInputValue] = useState('');

  // const postInputValue = async (postData, resetter = () => {}) => {
  //   const res = await fetch('https://gms.thinkfoundation.com.bd/api/schedule/mqtt-request', {
  //     method: 'POST',
  //     credentials: 'same-origin',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify(postData),
  //   });
  //   if (res.ok === true) {
  //     alert('data post successfully');
  //     resetter();
  //     // setInputValue('');
  //   } else {
  //     alert('Something is wrong');
  //   }
  // };

  return (
    <div className='flex'>
      <input
        className='mr-2 w-20 px-1 border border-slate-400 focus:outline-none'
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        type='text'
      />
      <button className='text-sm bg-indigo-500 px-2 text-white' onClick={() => postInputValue({ input_value: inputValue }, setInputValue(''))}>
        Send
      </button>
    </div>
  );
}
