import React, { useState } from 'react';
import { postInputValue } from '../../api';

export default function Switch2() {
  const [value, setValue] = useState(false);

  const handleSwitch = () => {
    setValue(!value);
    postInputValue({ input_value: value ? '4' : '3' });
  };
  return (
    <div className=''>
      <h3>Device 2</h3>
      <div onClick={handleSwitch} className={`w-8  p-2 relative rounded-lg  ${value ? 'bg-indigo-600 justify-end' : 'bg-slate-500'}`}>
        <div
          className={`w-2/5 absolute left-0.5 top-1/2 -translate-y-1/2 aspect-square  rounded-full bg-indigo-300 ${value && 'left-[calc(50%-2px)]'}`}
        ></div>
      </div>
    </div>
  );
}
