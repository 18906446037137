export async function postInputValue(postData, resetter = () => {}) {
  const res = await fetch('https://gms.thinkfoundation.com.bd/api/schedule/mqtt-request', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(postData),
  });
  if (res.ok === true) {
    alert('data post successfully');
    resetter();
    // setInputValue('');
  } else {
    alert('Something is wrong');
  }
}
