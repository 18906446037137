import React, { useState } from 'react';
import { ReactComponent as CrossIcon } from './crossIcon.svg';
import { ReactComponent as MarkIcon } from './markIcon.svg';

export function makeDateObjectToSecond(date, time) {
  const newDate = date + ' ' + time;
  return new Date(newDate).getTime() / 1000;
}

export default function DatePickerModalView({ onClose = () => {}, context = () => {} }) {
  const [date, setDate] = useState('');
  const [formData, updateFormData] = useState({
    name: '',
    start_time: '',
    end_time: '',
  });

  const getData = async () => {
    const postData = {
      name: formData.name,
      start_time: makeDateObjectToSecond(date, formData.start_time),
      end_time: makeDateObjectToSecond(date, formData.end_time),
    };
    const res = await fetch('https://gms.thinkfoundation.com.bd/api/schedule/create', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify([postData]),
    });
    if (res.ok === true) {
      alert('alarm set successfully');
      onClose();
      context();
    } else {
      alert('Something is wrong');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const copy = { ...formData };
    copy[name] = value;
    updateFormData(copy);
  };

  const onConFirm = () => {
    if (formData.start_time !== '' && formData.end_time !== '' && date !== '') getData();
    else alert('post data is not valid');
  };

  return (
    <div className='max-w-lg mx-auto'>
      <div className='w-full bg-slate-200 overflow-y-auto p-8'>
        <div className='mb-10 flex flex-row flex-nowrap items-center justify-between'>
          <div className='truncate'>
            <h2 className='uppercase font-medium text-xl'>New Reminder</h2>
          </div>
          <button onClick={onClose} type='button' className='p-1'>
            <CrossIcon />
          </button>
        </div>

        <div className='grid grid-flow-row grid-cols-12 gap-4'>
          <div className='mb-10 col-span-12'>
            <label className='block font-medium text-gray-700 '>What do you want to remember?</label>
            <input
              type='text'
              className='px-2 placeholder-gray-400 w-full h-10 bg-white border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm rounded-md  flex-grow'
              name='name'
              placeholder='description'
              value={formData.name}
              onChange={handleInputChange}
            />
          </div>
          <div className='mb-10 col-span-12'>
            <label className='block font-medium text-gray-700 '>Date:</label>
            <input
              type='date'
              className='px-2 placeholder-gray-400 w-full h-10 bg-white border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm rounded-md  flex-grow'
              name='date'
              placeholder='description'
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>
          <div className='col-span-6'>
            <label className=' block font-medium text-gray-700 '>Start Time:</label>
            <input
              type='time'
              className='px-2 w-full h-10 placeholder-gray-400 bg-white border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm rounded-md flex-grow'
              name='start_time'
              value={formData.start_time}
              onChange={handleInputChange}
            />
          </div>
          <div className='col-span-6'>
            <label className=' block font-medium text-gray-700 '>End Time:</label>

            <input
              type='time'
              className='px-2 w-full h-10 placeholder-gray-400 bg-white border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm rounded-md '
              name='end_time'
              value={formData.end_time}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className='mt-4 flex flex-row flex-wrap items-center justify-end'>
          <button
            onClick={onConFirm}
            type='submit'
            className='uppercase w-full sm:w-auto flex flex-row flex-nowrap items-center justify-center gap-2 px-4 py-2 shadow rounded text-lg font-medium transition-colors duration-150 bg-indigo-700 hover:bg-indigo-500 text-white'
          >
            <MarkIcon />
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}
